import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Card, Form, Container, Button, Modal } from "react-bootstrap";
import fondoQuiniela from '../../../img/logo-quiniela-prueba.png';
import fondoCPA from '../../../img/cpalogo.jpeg';
import ConsultasApi from '../../../shared/helpers/consultasAPI';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SaveIcon from '@mui/icons-material/Save';
import BtnVolver from "../../common/BtnVolver";
import Swal from "sweetalert2";

const openNewWindow = () => {
    const newWindow = window.open('', '', 'width=1024,height=768');
    newWindow.document.body.innerHTML = '<div id="modal-content"></div>';
    // newWindow.document.body.style.backgroundImage = `url(${backgroundImage})`;
    newWindow.document.body.style.backgroundRepeat = `no-repeat`;
    newWindow.document.body.style.backgroundSize = `100%`;
    newWindow.document.body.style.backgroundColor = `#f7f7f7`;
    newWindow.document.body.style.display = 'flex';
    newWindow.document.body.style.justifyContent = 'center';
    const styleSheets = Array.from(document.styleSheets);
    styleSheets.forEach((styleSheet) => {
        const cssLink = document.createElement('link');
        cssLink.rel = 'stylesheet';
        cssLink.href = styleSheet.href;
        newWindow.document.head.appendChild(cssLink);
    });
    return newWindow;
};

const CargarResultados = () => {
    const [premios, setPremios] = useState(Array(20).fill(''));
    const modalWindowRef = useRef(null);
    const URL_EXTRACTO = window.API_ROUTES.EXTRACTO;
    const [tipo_sorteo, setTipoSorteo] = useState('')
    const [numero, setNumero] = useState('');

    const URL_COLOR = window.API_ROUTES.COLOR;
    const URL_CONJUNTOBOLILLEROS = window.API_ROUTES.CONJUNTOBOLILLEROS;
    const URL_BOLILLEROS = window.API_ROUTES.BOLILLEROS;
    const [bolillero_mil, setBolilleroMil] = useState({ numero: '', color: '', ubicacion: 'Unidad de Mil' });
    const [bolillero_centena, setBolilleroCentena] = useState({ numero: '', color: '', ubicacion: 'Centena' });
    const [bolillero_decena, setBolilleroDecena] = useState({ numero: '', color: '', ubicacion: 'Decena' });
    const [bolillero_unidad, setBolilleroUnidad] = useState({ numero: '', color: '', ubicacion: 'Unidad' });
    const [bolillero_posicion, setBolilleroPosicion] = useState({ numero: '', color: '', ubicacion: 'Posicion' });
    const [comentario_incidente, setComentarioIncidente] = useState('');

    const formRef = useRef(null); // Crear la referencia al formulario
    const [tabla_colores, setTablaColores] = useState([]);
    const [tabla_bolilleros, setTablaBolilleros] = useState([]);
    const [tabla_colores_posicion, setTablaColoresPosicion] = useState([]);
    const [showModalIncidente, setShowModalIncidente] = useState(false)
    const [incidente, setIncidente] = useState('true')
    const selectedColors = [
        bolillero_mil.color,
        bolillero_centena.color,
        bolillero_decena.color,
        bolillero_unidad.color,
        bolillero_posicion.color,
    ];
    const selectedBolilleros = [
        bolillero_mil.numero,
        bolillero_centena.numero,
        bolillero_decena.numero,
        bolillero_unidad.numero,
        bolillero_posicion.numero,
    ];
    // Función para filtrar los colores disponibles
    const getAvailableColors = (currentColor) => {
        return tabla_colores.filter((color) =>
            !selectedColors.includes(color.id.toString()) || color.id.toString() === currentColor
        );
    };
    // Función para filtrar los bolillero disponibles
    const getAvailableBolilleros = (currentBolillero) => {
        return tabla_bolilleros.filter((bolillero) =>
            !selectedBolilleros.includes(bolillero.numero.toString()) || bolillero.numero.toString() === currentBolillero
        );
    };
    useEffect(() => {
        obtenerSorteo();
        cargarColores();
        obtenerBolillerosAnteriores();
        cargarBolilleros();
    }, []);

    const cargarColores = async () => {
        const colores = await ConsultasApi.ListarTodos(URL_COLOR);
        const colores_posicion = [];
        const colores_unidades = [];
        colores.data.results.forEach(color => {
            if (color.detalle === "Azul" || color.detalle === "Naranja") {
                colores_posicion.push(color);
            } else {
                colores_unidades.push(color);
            };
        });
        setTablaColores(colores_unidades);
        setTablaColoresPosicion(colores_posicion);
    };

    const cargarBolilleros = async () => {
        const bolilleros = await ConsultasApi.ListarTodos(URL_BOLILLEROS);
        setTablaBolilleros(bolilleros.data.results)
    };

    const obtenerSorteo = async () => {
        const sorteo = await ConsultasApi.ObtenerObjeto(URL_EXTRACTO + 'obtenerSorteo/', 1)
        setNumero(sorteo.data.sorteo + 1)
        setTipoSorteo(sorteo.data.tipo_detalle.tipo)
    }

    const obtenerBolillerosAnteriores = async () => {
        const bolilleros = await ConsultasApi.ListarTodos(URL_CONJUNTOBOLILLEROS + 'obtener_bolilleros/')
        if (bolilleros.data.length > 0) {
            bolilleros.data.forEach(bolillero => {
                if (bolillero.bolilla_detalle.split(' - ')[1] === 'Unidad de Mil') {
                    setBolilleroMil({ numero: bolillero.bolillero_detalle, color: '', ubicacion: 'Unidad de Mil' });
                } else {
                    if (bolillero.bolilla_detalle.split(' - ')[1] === 'Centena') {
                        setBolilleroCentena({ numero: bolillero.bolillero_detalle, color: '', ubicacion: 'Centena' });
                    } else {
                        if (bolillero.bolilla_detalle.split(' - ')[1] === 'Decena') {
                            setBolilleroDecena({ numero: bolillero.bolillero_detalle, color: '', ubicacion: 'Decena' });
                        } else {
                            if (bolillero.bolilla_detalle.split(' - ')[1] === 'Unidad') {
                                setBolilleroUnidad({ numero: bolillero.bolillero_detalle, color: '', ubicacion: 'Unidad' });
                            } else {
                                setBolilleroPosicion({ numero: bolillero.bolillero_detalle, color: '', ubicacion: 'Posicion' });
                            };
                        };
                    };
                };
            });
        };
    };

    const guardar = () => {
        const form = formRef.current;
        if (form.checkValidity()) {
            setShowModalIncidente(true)

        } else {
            form.reportValidity();
        };
    };

    const guardarModal = (event) => {
        event.preventDefault()
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Esta accion va a guardar el registro de los bolilleros e incidentes",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#008185",
            cancelButtonColor: "#d33",
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const objeto_incidente = {
                    bolilleros: [
                        bolillero_mil,
                        bolillero_centena,
                        bolillero_decena,
                        bolillero_unidad,
                        bolillero_posicion,
                    ],
                    comentario_incidente: comentario_incidente,
                    numero_sorteo: numero,
                }
                try {
                    const creacion = await ConsultasApi.CrearObjeto(URL_CONJUNTOBOLILLEROS + 'crear_conjunto/', objeto_incidente)
                    if (creacion.status === 200) {
                        Swal.fire(
                            'Creación exitosa',
                            'Se guardo con exito el reporte',
                            'success'
                        );
                        setShowModalIncidente(false);
                        setIncidente('true');
                        setComentarioIncidente('');
                    } else {
                        Swal.fire(
                            'Creación erronea',
                            'No se guardo el reporte',
                            'error'
                        );
                    };
                } catch (error) {
                    console.error(error)
                    if (error.response && error.response.data && error.response.data.error) {
                        Swal.fire(
                            'Creación erronea',
                            error.response.data.error,
                            'error'
                        );
                    } else {
                        Swal.fire(
                            'Creación erronea',
                            'No se guardo el reporte',
                            'error'
                        );
                    };
                };
            }
        });
    }

    const openModal = () => {
        const newWindow = openNewWindow();
        modalWindowRef.current = newWindow;
        renderModalContent(newWindow);
    };

    const limpiarVentana = () => {
        setPremios(Array(20).fill(''));
        obtenerSorteo();
        renderModalContent(modalWindowRef.current);
    };

    const renderModalContent = (newWindow) => {
        const middleIndex = Math.ceil(premios.length / 2); // Encuentra el índice medio

        const firstHalf = premios.slice(0, middleIndex);
        const secondHalf = premios.slice(middleIndex);

        if (newWindow) {
            newWindow.document.getElementById('modal-content').innerHTML = `
                <style>
                .container-todo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .container-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 0px;
                    column-gap: 10%;
                    width: 100%;
                }
                .container-title {
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 15px;
                }
                .container-title h{
                    font-size: 50px;
                    color: black;
                    font-family: Arial, sans-serif;
                }
                .container-prizes {
                    height: 81vh;
                    width: 90%;
                    background-color:#007c84;
                    display:flex;
                    flex-direction: row;
                    border-radius: 8px;
                }

                .col-prize {
                    display:flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: center;
                    margin: 3%;
                    row-gap: 2%;
                }

                .row-prize {
                    display:flex;
                    width:100%;
                    justify-content: center;
                    align-items: center;
                    gap: 2%;
                    font-size: 6.84vh;
                    margin-bottom: -13px;
                }

                .div-prize {
                    display:flex;
                    width:35%;
                    border-radius: 8px;
                    height: 7.9vh;
                }

                .div-prize h{
                    width:100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: black;
                    font-family: Arial, sans-serif;
                    font-size: 3vh;
                }

                .square {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow:1;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }
                .square h{
                    color: black;
                    font-family: Arial, sans-serif;
                    font-weight: bold;
                }
                .imagen {
                    width:15%;
                }
                .imagen-quiniela {
                    width:20%;
                }
                </style>
                <div class="container-todo">
                    <div class="container-header">
                        <img class="imagen-quiniela" src=${fondoQuiniela}></img>
                        <div class="container-title">
                            <h><b>Sorteo ${tipo_sorteo} N° ${numero}</b></h>
                            <h>${moment().format('dddd DD [de] MMMM YYYY, h:mm a').charAt(0).toUpperCase() + moment().format('dddd DD [de] MMMM YYYY').slice(1)}</h>
                        </div>
                        <img class="imagen" src=${fondoCPA}></img>
                    </div>
                    <div class="container-prizes">
                        <div class="col-prize">
                            ${firstHalf.map((valor, index) => `
                                    <div class="row-prize">
                                        <div class="div-prize" style="background-color:${(index % 2 === 0) ? '#e6e6e6' : '#cccccc'}">
                                            <h class="h-prize">${index + 1}° Premio</h>
                                        </div>
                                        <div class="square" style="background-color:${(index % 2 === 0) ? '#e6e6e6' : '#cccccc'}">
                                            <h>
                                                ${valor !== '' ? valor : "&nbsp"}
                                            </h>
                                        </div>
                                    </div>
                                `).join('')}
                        </div>
                        <div class="col-prize">
                            ${secondHalf.map((valor, index) => `
                                    <div class="row-prize">
                                        <div class="div-prize" style="background-color:${(index % 2 === 0) ? '#e6e6e6' : '#cccccc'}">
                                            <h class="h-prize">${index + 11}° Premio</h>
                                        </div>
                                        <div class="square" style="background-color:${(index % 2 === 0) ? '#e6e6e6' : '#cccccc'}">
                                            <h>
                                                ${valor !== '' ? valor : "&nbsp"}
                                            </h>
                                        </div>
                                    </div>
                                `).join('')}
                        </div>
                    </div>
                </div>
            `;
            newWindow.document.getElementById('modal-content').style.width = '100%'
        }
    };

    const handleInputChange = (index, value) => {
        const updatedPremios = [...premios];
        const sanitizedValue = value.slice(0, 4);
        if (/^\d*$/.test(sanitizedValue)) {
            updatedPremios[index] = sanitizedValue;
            setPremios(updatedPremios);
        }
    };

    const handleFormKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowLeft') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex - 10];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowRight') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 10];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex + 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault(); // Previene el comportamiento predeterminado de la tecla Enter
            if (modalWindowRef.current) {
                renderModalContent(modalWindowRef.current);
            }
            // Encuentra el índice del campo actual dentro del formulario
            const formControls = event.target.form.elements;
            const currentIndex = Array.from(formControls).indexOf(event.target);

            // Encuentra el siguiente campo y enfócalo
            const nextControl = formControls[currentIndex - 1];
            if (nextControl) {
                nextControl.focus();
            }
        }
    };

    return (
        <Container className="mainSection">
            <Row>
                <Col>
                    <h1 className="py-4 fw-bold">Resultados</h1>
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    <Card.Title>Bolilleros</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form ref={formRef}>
                        <Row className="m-3 justify-content-center" >
                            <Form.Group as={Col} md="2">
                                <Form.Label>Unidad de Mil</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={bolillero_mil.numero}
                                    onChange={(event) => {
                                        setBolilleroMil({ color: bolillero_mil.color, numero: event.target.value, ubicacion: 'Unidad de Mil' });
                                    }}
                                >
                                    <option value="" hidden>Elegir Bolillero</option>
                                    {
                                        tabla_bolilleros.length > 0 ?
                                            getAvailableBolilleros(bolillero_mil.numero).map((bolillero) => (
                                                <option key={bolillero.id} value={bolillero.numero}>
                                                    {bolillero.numero}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Centena</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={bolillero_centena.numero}
                                    onChange={(event) => {
                                        setBolilleroCentena({ color: bolillero_centena.color, numero: event.target.value, ubicacion: 'Centena' });
                                    }}
                                >
                                    <option value="" hidden>Elegir Bolillero</option>
                                    {
                                        tabla_bolilleros.length > 0 ?
                                            getAvailableBolilleros(bolillero_centena.numero).map((bolillero) => (
                                                <option key={bolillero.id} value={bolillero.numero}>
                                                    {bolillero.numero}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Decena</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={bolillero_decena.numero}
                                    onChange={(event) => {
                                        setBolilleroDecena({ color: bolillero_decena.color, numero: event.target.value, ubicacion: 'Decena' });
                                    }}
                                >
                                    <option value="" hidden>Elegir Bolillero</option>
                                    {
                                        tabla_bolilleros.length > 0 ?
                                            getAvailableBolilleros(bolillero_decena.numero).map((bolillero) => (
                                                <option key={bolillero.id} value={bolillero.numero}>
                                                    {bolillero.numero}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Unidad</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={bolillero_unidad.numero}
                                    onChange={(event) => {
                                        setBolilleroUnidad({ color: bolillero_unidad.color, numero: event.target.value, ubicacion: 'Unidad' });
                                    }}
                                >
                                    <option value="" hidden>Elegir Bolillero</option>
                                    {
                                        tabla_bolilleros.length > 0 ?
                                            getAvailableBolilleros(bolillero_unidad.numero).map((bolillero) => (
                                                <option key={bolillero.id} value={bolillero.numero}>
                                                    {bolillero.numero}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Posición</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={bolillero_posicion.numero}
                                    onChange={(event) => {
                                        setBolilleroPosicion({ color: bolillero_posicion.color, numero: event.target.value, ubicacion: 'Posicion' });
                                    }}
                                >
                                    <option value="" hidden>Elegir Bolillero</option>
                                    {
                                        tabla_bolilleros.length > 0 ?
                                            getAvailableBolilleros(bolillero_posicion.numero).map((bolillero) => (
                                                <option key={bolillero.id} value={bolillero.numero}>
                                                    {bolillero.numero}
                                                </option>
                                            ))
                                            : null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <h style={{ marginLeft: '25px', fontWeight: 'bold' }}>Color de Bolilla</h>
                        <Row className="m-3 justify-content-center" >
                            <Form.Group as={Col} md="2">
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Form.Control
                                        as="select"
                                        required
                                        value={bolillero_mil.color}
                                        onChange={(event) => {
                                            setBolilleroMil({ numero: bolillero_mil.numero, color: event.target.value, ubicacion: 'Unidad de Mil' });
                                        }}
                                    >
                                        <option value="" hidden>Elegir Color</option>
                                        {
                                            tabla_colores.length > 0 ?
                                                getAvailableColors(bolillero_mil.color).map((color) => (
                                                    <option key={color.id} value={color.id}>
                                                        {color.detalle}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </Form.Control>
                                    <button
                                        type="button"
                                        onClick={() => setBolilleroMil({ numero: bolillero_mil.numero, color: '', ubicacion: 'Unidad de Mil' })}
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            fontSize: '16px',
                                            color: 'grey',
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Form.Control
                                        as="select"
                                        required
                                        value={bolillero_centena.color}
                                        onChange={(event) => {
                                            setBolilleroCentena({ numero: bolillero_centena.numero, color: event.target.value, ubicacion: 'Centena' });
                                        }}
                                    >
                                        <option value="" hidden>Elegir Color</option>
                                        {
                                            tabla_colores.length > 0 ?
                                                getAvailableColors(bolillero_centena.color).map((color) => (
                                                    <option key={color.id} value={color.id}>
                                                        {color.detalle}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </Form.Control>
                                    <button
                                        type="button"
                                        onClick={() => setBolilleroCentena({ numero: bolillero_centena.numero, color: '', ubicacion: 'Centena' })}
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            fontSize: '16px',
                                            color: 'grey',
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Form.Control
                                        as="select"
                                        required
                                        value={bolillero_decena.color}
                                        onChange={(event) => {
                                            setBolilleroDecena({ numero: bolillero_decena.numero, color: event.target.value, ubicacion: 'Decena' });
                                        }}
                                    >
                                        <option value="" hidden>Elegir Color</option>
                                        {
                                            tabla_colores.length > 0 ?
                                                getAvailableColors(bolillero_decena.color).map((color) => (
                                                    <option key={color.id} value={color.id}>
                                                        {color.detalle}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </Form.Control>
                                    <button
                                        type="button"
                                        onClick={() => setBolilleroDecena({ numero: bolillero_decena.numero, color: '', ubicacion: 'Decena' })}
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            fontSize: '16px',
                                            color: 'grey',
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Form.Control
                                        as="select"
                                        required
                                        value={bolillero_unidad.color}
                                        onChange={(event) => {
                                            setBolilleroUnidad({ numero: bolillero_unidad.numero, color: event.target.value, ubicacion: 'Unidad' });
                                        }}
                                    >
                                        <option value="" hidden>Elegir Color</option>
                                        {
                                            tabla_colores.length > 0 ?
                                                getAvailableColors(bolillero_unidad.color).map((color) => (
                                                    <option key={color.id} value={color.id}>
                                                        {color.detalle}
                                                    </option>
                                                ))
                                                : null
                                        }
                                    </Form.Control>
                                    <button
                                        type="button"
                                        onClick={() => setBolilleroUnidad({ numero: bolillero_unidad.numero, color: '', ubicacion: 'Unidad' })}
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            fontSize: '16px',
                                            color: 'grey',
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Form.Control
                                        as="select"
                                        required
                                        value={bolillero_posicion.color}
                                        onChange={(event) => {
                                            setBolilleroPosicion({ numero: bolillero_posicion.numero, color: event.target.value, ubicacion: 'Posicion' });
                                        }}
                                        style={{ paddingRight: '30px' }} // Para dar espacio al ícono
                                    >
                                        <option value="" hidden>Elegir Color</option>
                                        {tabla_colores_posicion.length > 0
                                            ? tabla_colores_posicion.map((color) => (
                                                <option key={color.id} value={color.id}>
                                                    {color.detalle}
                                                </option>
                                            ))
                                            : null}
                                    </Form.Control>
                                    <button
                                        type="button"
                                        onClick={() => setBolilleroPosicion({ numero: bolillero_posicion.numero, color: '', ubicacion: 'Posicion' })}
                                        style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: 0,
                                            fontSize: '16px',
                                            color: 'grey',
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title style={{ fontSize: '30px' }}>Sorteo {tipo_sorteo.charAt(0) + tipo_sorteo.slice(1).toLowerCase()} - N° {numero} - {moment().format('DD/MM/YYYY')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onKeyDown={handleFormKeyDown} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Row className="m-3" >
                            <Col md="6">
                                {[...Array(10).keys()].map((i) => (
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row', marginBottom: '5%' }} key={i}>
                                        <Form.Label style={{ fontSize: '20px', marginRight: i === 9 ? '1%' : '5%' }}>{i + 1}°</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={premios[i]}
                                            onChange={(event) => handleInputChange(i, event.target.value)}
                                            style={{ fontWeight: 'bold', fontSize: '20px' }}
                                        />
                                    </Form.Group>
                                ))}
                            </Col>
                            <Col md="6">
                                {[...Array(10).keys()].map((i) => (
                                    <Form.Group style={{ display: 'flex', flexDirection: 'row', marginBottom: '5%' }} key={i + 10}>
                                        <Form.Label style={{ fontSize: '20px', marginRight: '5%' }}>{i + 11}°</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={premios[i + 10]}
                                            onChange={(event) => handleInputChange(i + 10, event.target.value)}
                                            style={{ fontWeight: 'bold', fontSize: '20px' }}
                                        />
                                    </Form.Group>
                                ))}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <section className="d-flex justify-content-end my-3">
                <Button className="botonCPA m-3" onClick={limpiarVentana}>
                    <BackspaceIcon style={{ marginRight: '5px' }} />
                    Limpiar
                </Button>
                <Button className="botonCPA m-3" onClick={openModal}>
                    <OpenInNewIcon style={{ marginRight: '5px' }} />
                    Abrir Ventana
                </Button>
                <Button className="botonCPA m-3" onClick={guardar}>
                    <SaveIcon style={{ marginRight: '5px' }} />
                    Guardar
                </Button>
                <BtnVolver className="botonCPA m-3" style={{ margin: '1%', marginTop: '16px', height: 'fit-content' }} />
            </section>
            <Modal centered show={showModalIncidente}>
                <Modal.Header closeButton onClick={() => {
                    setShowModalIncidente(false);
                    setIncidente('true');
                    setComentarioIncidente('');
                }}>
                    <Modal.Title>Incidentes</Modal.Title>
                </Modal.Header>
                <Form onSubmit={guardarModal}>
                    <Modal.Body>
                        <Row className="m-3 justify-content-center" >
                            <Form.Group as={Col} md="6">
                                <Form.Label>Hubo Incidentes?</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                    value={incidente}
                                    onChange={(event) => {
                                        setIncidente(event.target.value);
                                    }}
                                >
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        {incidente === 'true' ?
                            <Row className="m-3 justify-content-center" >
                                <Form.Group as={Col} md="12">
                                    <Form.Control
                                        required
                                        as="textarea"
                                        value={comentario_incidente}
                                        onChange={(event) => {
                                            setComentarioIncidente(event.target.value);
                                        }}
                                        placeholder="Ingrese comentario sobre el incidente ocurrido"
                                    />
                                </Form.Group>
                            </Row>
                            :
                            null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="botonCPA" type="submit">
                            <SaveIcon style={{ marginRight: '5px' }} />
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>
    );
}

export default CargarResultados;
